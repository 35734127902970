<template>
  <a-modal
    :visible="visible"
    width="680px"
    :footer="null"
    :maskClosable="false"
    :bodyStyle="{ padding: '40px' }"
    @cancel="modalCancel(defaultId)"
  >
    <!-- modal 标题 -->
    <h1 class="modal_title">选择门店</h1>

    <!-- 店铺列表 -->
    <a-radio-group v-model="selectShopId">
      <div
        class="listItem"
        v-for="item in shopList"
        :key="item.shop_id"
      >
        <!-- 店铺名称-单选 -->
        <a-radio class="listItem_title" :value="item.shop_id">
          <div class="item_title_text">{{ item.name }}</div>
        </a-radio>

        <!-- 店铺描述 -->
        <div class="item_descript">{{ item.des }}</div>
      </div>
    </a-radio-group>

    <!-- 确认按钮 -->
    <a-button block class="footer" type="primary" @click="modalOk">确定</a-button>
  </a-modal>
</template>

<script>
import { menuNeedObj, checkDataType } from '@/utils/utils'

export default {
  data() {
    return {
      visible: false, // modal的弹窗状态
      defaultId: '', // 接口返回的默认选中id
      selectShopId: '', // 选中的商铺id
      resolve: null, // 返回操作
      shopList: [], // 店铺列表信息
    }
  },
  methods: {
    // 进入页面初始话
    init(list = [], defaultId) {
      return new Promise((resolve) => {
        let menu = {
          name: 'title',
          shop_id: 'id',
          des: 'address.name',
        }

        this.visible = true
        this.shopList = list.map((item) => menuNeedObj(item, menu))
        this.selectShopId = defaultId
        this.defaultId = defaultId
        this.resolve = resolve
      })
    },

    // 点击取消按钮
    modalCancel(shop_id) {
      this.visible = false
      this.selectShopId = ''
      checkDataType(this.resolve, 'function') && this.resolve(shop_id)
    },

    // 点击确认按钮
    modalOk() {
      this.modalCancel(this.selectShopId)
    },
  }
}
</script>

<style lang="less" scoped>
// modal 标题
.modal_title {
  height: 24px;
  font-family: PingFangSC-Medium;
  font-weight: 500;
  font-size: 24px;
  color: rgba(0,0,0,0.85);
  text-align: center;
  line-height: 24px;
  margin-bottom: 40px;
}

// 店铺列表
.listItem {
  margin-top: 20px;
  // 店铺名称-单选
  .listItem_title {
    display: flex;
    align-items: center;
    font-family: PingFangSC-Medium;
    font-weight: 500;
    font-size: 20px;
    color: #333333;
    line-height: 22px;
    margin-bottom: 8px;

    ::v-deep .ant-radio {
      transform: scale(1.3);
      margin-right: 3px;
    }
  }

  // 店铺描述
  .item_descript {
    padding-left: 28px;
    font-family: PingFangSC-Regular;
    font-weight: 400;
    font-size: 14px;
    color: #666666;
    line-height: 22px;
  }
}

// 确认按钮
.footer {
  width: 300px;
  height: 52px;
  background: #E43937;
  border-radius: 2px;
  font-family: PingFangSC-Medium;
  font-weight: 500;
  font-size: 18px;
  color: #FFFFFF;
  display: block;
  margin: 40px auto 0;
}
</style>
